import React from 'react';
import { Grid, Typography, Box, IconButton, useMediaQuery } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import xLogo from '../images/xlogo.svg';

const Footer = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Box sx={{ backgroundColor: '#0A2B5B', color: 'white', padding: 2 }}>
      <Grid container spacing={2} justifyContent="space-between">
        {!isMobile && (
          <Grid item xs={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body2">
              In Touch Sports (ITS), was founded in 2001, by Chairman & CEO, James Adams who’s business and accounting acumen was brought to the fore, in creating the business that it is today.
            </Typography>
          </Grid>
        )}
        <Grid item xs={isMobile ? 12 : 6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {!isMobile && <Typography variant="h6">Social Media</Typography>}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton href="https://www.facebook.com/InTouchSports/" target="_blank" color="inherit">
              <FacebookIcon />
            </IconButton>
            <IconButton href="https://x.com/InTouchSports" target="_blank" color="inherit">
              <img src={xLogo} alt="X Logo" style={{ width: 24, height: 24 }} />
            </IconButton>
            <IconButton href="https://www.instagram.com/intouchsports/?hl=en" target="_blank" color="inherit">
              <InstagramIcon />
            </IconButton>
          </Box>
        </Grid>
        {!isMobile && (
          <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Typography variant="body2" gutterBottom>
              office@intouchsports.com
            </Typography>
            <Typography variant="body2" gutterBottom>
              SA: +27 (0) 12 752 1000
            </Typography>
            <Typography variant="body2" gutterBottom>
              UK: +44 (0) 207 924 6780
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Footer;