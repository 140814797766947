import React from 'react';
import { Modal, Box, Typography, Button, Avatar } from '@mui/material';

function ClientModal({ open, onClose, client }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="client-details"
      aria-describedby="client-more-info"
    >
      <Box sx={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        width: '80%', 
        maxWidth: 800, 
        bgcolor: '#f0f0f0', // Light gray background color
        boxShadow: 24, 
        p: 4, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        borderRadius: '10px'
      }}>
        <Box sx={{ display: 'flex', width: '100%', mb: 2, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <Typography id="client-name" variant="h6" component="h2" sx={{ mt: 2, fontWeight: 'bold', textAlign: 'center' }}>
            {client.name}
          </Typography>
          <Typography id="client-club" sx={{ mt: 1, fontWeight: 'bold', textAlign: 'center' }}>
            {client.club}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar
              src={client.image || '/images/default.png'}
              alt={client.name}
              sx={{ width: '100%', height: 'auto', borderRadius: '10px' }}
          />
          <Typography id="client-review" sx={{ mt: 2, textAlign: 'center' }}>
            {client.review}
          </Typography>
        </Box>
        <Button onClick={onClose} variant="contained" sx={{ mt: 2 }}>
          Close
        </Button>
      </Box>
    </Modal>
  );
}

export default ClientModal;
