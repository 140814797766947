import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ClientsPage from './pages/ClientsPage';
import TeamPage from './pages/TeamPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import NavBar from './components/navbar';
import ServicesPage from './pages/ServicesPage';
import './App.css';

function preloadPublicImages() {
  const imageContext = require.context('../public/images', true, /\.(png|jpe?g|svg|webp)$/);  // Set to true to include subdirectories
  imageContext.keys().forEach((image) => {
    const img = new Image();
    img.src = imageContext(image);
  });
}

function App() {
  useEffect(() => {
    preloadPublicImages(); // Preload all images from public/images directory and its subdirectories
  }, []);

  return (
    <Router>
      <div className="App">
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/clients" element={<ClientsPage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/services" element={<ServicesPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;