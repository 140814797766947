import React from 'react';
import Slider from 'react-slick';
import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BusinessIcon from '@mui/icons-material/Business';

// Manually specify all image paths
const brandImages = [
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/breitling.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/investec.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/nike.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/puma.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/toyota.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/Xbox-Logo.wine.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/canterbury.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/jbl.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/oakley.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/sab.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/beats.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/defy.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/mercedes.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/optimum.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/sun.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/bose.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/gilbert.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/mizuno.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/playstation.webp',
  'https://webpfilesits.s3.eu-west-2.amazonaws.com/thule.webp'
];

const BrandSlider = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const slidesToShow = isSmallScreen ? 2 : 4;

  const settings = {
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 400,
    cssEase: 'linear',
    arrows: false,
  };

  return (
    <Container
      sx={{
        bgcolor: 'white',
        py: 4,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        boxShadow: 3,
        mt: 4,
        width: '85%', // Use the same width as ClientCarousel
        margin: '0 auto', // Center the component on all screen sizes
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 12 }}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: 'linear-gradient(45deg, #134083 30%, #5A9BD3 90%)',
            borderRadius: 8,
            p: 2,
            color: 'white',
            width: isSmallScreen ? '100%' : '50%',
            justifyContent: 'center',
            fontWeight: 'bold',
            boxShadow: 3,
          }}
        >
          <BusinessIcon sx={{ mr: 2 }} fontSize="large" /> Brands We Work With
        </Typography>
      </Box>
      <Slider {...settings}>
        {brandImages.map((image, index) => (
          <Box 
            key={index} 
            sx={{ 
              px: isSmallScreen ? 2 : 4, // Adjust padding for small screens
              height: isSmallScreen ? '200px' : '150px' // Increase height for small screens
            }}
          >
            <Box
              component="img"
              src={image}
              alt={`Brand ${index + 1}`}
              sx={{ 
                width: '100%', 
                maxHeight: isSmallScreen ? '200px' : '150px', // Adjust maxHeight for small screens
                objectFit: 'contain' 
              }}
            />
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default BrandSlider;
