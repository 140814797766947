import React from 'react';
import TeamCarousel from './TeamCarousel'; // Ensure the correct import path
import { Typography, Box } from '@mui/material';

const TeamSection = () => {
  return (
    <div style={{ padding: '40px 0' }}>
      <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 20px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'transparent',
            borderRadius: 2,
            color: '#0A2B5B',
            width: 'fit-content',
            mx: 'auto',
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            Meet Our Team
          </Typography>
        </Box>
        <Box>
          <TeamCarousel />
        </Box>
      </div>
    </div>
  );
};

export default TeamSection;
