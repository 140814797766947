import React from 'react';
import { Card, CardActionArea, CardContent, Typography, useMediaQuery, useTheme } from '@mui/material';

function ClientCard({ client, onClick }) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen size is less than 600px

    return (
        <Card 
            sx={{ 
                maxWidth: isSmallScreen ? 'calc(100% - 30px)' : 600, // Set max width to fit smaller screens with padding
                minWidth: isSmallScreen ? 'calc(100% - 30px)' : 400, // Set min width to fit smaller screens with padding
                margin: '20px', 
                boxShadow: 3, 
                textAlign: 'center', 
                borderRadius: '20px' 
            }}
        >
            <CardActionArea onClick={onClick} sx={{ padding: '20px', borderRadius: '20px' }}>
                <div style={{ width: '100%', height: '300px', overflow: 'hidden', borderRadius: '20px' }}>
                    <img
                        src={client.image || '/images/default.png'}
                        alt={client.name}
                        style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '20px' }}
                    />
                </div>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {client.name.toUpperCase()}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {client.club}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default ClientCard;
