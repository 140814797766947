import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemIcon, ListItemText, Grid, Card, CardContent, useMediaQuery, useTheme } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import GavelIcon from '@mui/icons-material/Gavel';

// Use the S3 WebP version
const getty1 = 'https://webpfilesits.s3.eu-west-2.amazonaws.com/services2.webp';

const services = [
  { icon: <BrandingWatermarkIcon />, text: 'Brand Management', description: 'We help players build and maintain a strong, positive personal brand that enhances their marketability and public profile.' },
  { icon: <MonetizationOnIcon />, text: 'Commercial Endorsements', description: 'We identify and secure lucrative endorsement deals that align with our clients\' personal brands and career goals.' },
  { icon: <GavelIcon />, text: 'Contract Negotiation', description: 'We leverage our industry expertise to secure the best possible contract terms for our clients, ensuring their professional and financial interests are protected.' }
];

const CommercialServices = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen size is less than 600px

  return (
    <Container sx={{ bgcolor: '#134083', color: 'white', py: 4, borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 8, borderBottomRightRadius: 8, boxShadow: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
        <Typography 
          variant="h4" 
          component="div" 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            background: 'linear-gradient(45deg, #FFFFFF 30%, #FFFFFF 90%)', 
            borderRadius: 8, 
            p: 2, 
            color: '#134083', 
            width: isSmallScreen ? '100%' : '50%', // Adjust the width based on screen size
            justifyContent: 'center', 
            fontWeight: 'bold', 
            boxShadow: 3 
          }}
        >
          <MonetizationOnIcon sx={{ mr: 2 }} fontSize="large" /> Commercial Services
        </Typography>
      </Box>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <List>
            {services.map((service, index) => (
              <ListItem key={index} sx={{ display: 'block', mb: 5 }}>
                <Card sx={{ display: 'flex', alignItems: 'center', p: 2, boxShadow: 3, bgcolor: 'white', color: 'black', '&:hover': { boxShadow: 6 } }}>
                  <ListItemIcon sx={{ minWidth: '48px', color: 'black' }}>
                    {service.icon}
                  </ListItemIcon>
                  <CardContent>
                    <ListItemText
                      primary={<Typography variant="h6" sx={{ fontWeight: 'bold', color: 'black' }}>{service.text}</Typography>}
                      secondary={<Typography variant="body1" sx={{ color: 'black' }}>{service.description}</Typography>}
                    />
                  </CardContent>
                </Card>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box component="img" src={getty1} alt="Commercial Services" sx={{ width: '100%', borderRadius: '8px' }} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CommercialServices;