import React from 'react';
import { Typography, Box, Container } from '@mui/material';
import WhoWeAre from '../components/AboutUs/WhoWeAre';
import KeyAchievements from '../components/AboutUs/KeyAchievements';
import TeamSection from '../components/AboutUs/TeamSection';
import ContactUs from '../components/AboutUs/ContactUs';
import Footer from '../components/Footer';

const AboutPage = () => (
  <Box
    sx={{
      backgroundImage: `url('https://webpfilesits.s3.eu-west-2.amazonaws.com/getty3.webp')`,  // Updated path
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Container maxWidth="lg" sx={{ textAlign: 'left', py: 4 }}>
      <Box
        py={5}
        sx={{
          px: {
            xs: 2, // padding x on extra small screens
            sm: 3, // padding x on small screens
            md: 5, // padding x on medium screens
            lg: 8, // padding x on large screens
          },
        }}
      >
        <Typography
          variant="h2"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            width: '100%',
            fontSize: {
              xs: '1.5rem', 
              sm: '2rem', 
              md: '2.5rem', 
              lg: '3rem', 
            },
            textAlign: 'center',
            color: 'white', // Change text color to white
          }}
        >
          EXCELLENCE IN SPORTS MANAGEMENT
        </Typography>
      </Box>

      <Box sx={{ bgcolor: '#f5f5f5', py: 4, px: 3, borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
        <WhoWeAre />
      </Box>
      
      <Box sx={{ bgcolor: '#0A2B5B', py: 4, px: 3, color: 'white' }}>
        <KeyAchievements />
      </Box>
      
      <Box sx={{ bgcolor: '#f5f5f5', py: 4, px: 3 }}>
        <TeamSection />
      </Box>
      
      <Box sx={{ bgcolor: '#0A2B5B', py: 4, px: 3, color: 'white', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
        <ContactUs />
      </Box>
    </Container>
    <Footer />
  </Box>
);

export default AboutPage;