import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Card, CardMedia, CardContent, Typography, Box, Fab, useTheme, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PeopleIcon from '@mui/icons-material/People';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Carousel.css'; // Ensure CSS file is correctly linked

const ClientCarousel = () => {
  const [clients, setClients] = useState([]);
  const sliderRef = useRef(null);
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    // Importing client data correctly
    import('../data/clients.json') // Adjust the path as necessary
      .then(response => {
        const formattedClients = response.clients.map(client => ({
          ...client,
          image: client.image || 'path/to/default/image.jpg' // Adjust default image path as necessary
        }));
        setClients(formattedClients);
      })
      .catch(error => console.error("Failed to load client data:", error));
  }, []);

  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isXSmall ? 1 : isSmall ? 2 : 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: false,
    variableWidth: false,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200, // Adjust according to your theme breakpoints
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 960, // Adjust according to your theme breakpoints
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <Box 
      sx={{ 
        bgcolor: 'white', 
        py: 4, 
        borderTopLeftRadius: 10, 
        borderTopRightRadius: 10, 
        borderBottomLeftRadius: 8, 
        borderBottomRightRadius: 8, 
        boxShadow: 3, 
        mt: 4, 
        width: '85%', // Set the width to 85% of the page
        margin: '0 auto', // Center the component horizontally
        position: 'relative' // Ensure the arrows are positioned within this box
      }}
    >
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        mb: 8, 
        mx: isSmall ? '29.25px' : 0 // Add margin-x for small screens
      }}
    >
      <Typography 
        variant="h5"
        component="div" 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          background: 'linear-gradient(45deg, #134083 30%, #5A9BD3 90%)', 
          borderRadius: 8, 
          p: 2, 
          color: 'white', 
          width: isSmall ? '100%' : '50%', // Adjust width for small screens
          justifyContent: 'center', 
          fontWeight: 'bold', 
          boxShadow: 3 
        }}
      >
        <PeopleIcon sx={{ mr: 2 }} fontSize="large" /> Our Clients
      </Typography>
    </Box>
      <Slider ref={sliderRef} {...settings}>
        {clients.map(client => (
          <div key={client.name} className="slick-slide-item">
            <Card className="client_card">
              <CardMedia
                component="img"
                className="client_card-media"
                image={process.env.PUBLIC_URL + client.image}
                alt={client.name}
              />
              <CardContent className="client_card-content">
                <Typography gutterBottom variant="h5" component="div">
                  {client.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Club: {client.club}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Nationality: {client.nationality}
                </Typography>
              </CardContent>
            </Card>
          </div>
        ))}
      </Slider>
      <Fab 
        color="primary" 
        aria-label="prev" 
        className="fab-hidden" 
        sx={{ 
          position: 'absolute', 
          top: '50%', 
          left: 16, 
          transform: 'translateY(-50%)', 
          zIndex: 2 // Ensure the arrow is above other elements 
        }} 
        onClick={() => sliderRef.current.slickPrev()}
      >
        <ArrowBackIcon />
      </Fab>
      <Fab 
        color="primary" 
        aria-label="next" 
        className="fab-hidden" 
        sx={{ 
          position: 'absolute', 
          top: '50%', 
          right: 16, 
          transform: 'translateY(-50%)', 
          zIndex: 2 // Ensure the arrow is above other elements 
        }} 
        onClick={() => sliderRef.current.slickNext()}
      >
        <ArrowForwardIcon />
      </Fab>
    </Box>
  );
}

export default ClientCarousel;
