import React, { useState, useEffect } from 'react';
import { Typography, Box, IconButton, Card, CardContent, useTheme, useMediaQuery } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import WillieLeRouxImage from '../images/testimonials/WillieLeRoux.jpg';
import CananMoodieImage from '../images/testimonials/CananMoodie.jpg';
import KurtLeeArendseImage from '../images/testimonials/KurtLeeArendse.jpg';
import MarcellCoetzeeImgage from '../images/testimonials/MarcellCoetzee.jpg';
import RuanNortjeImage from '../images/testimonials/RuanNortje.jpg';
import JeanClaudeMarsImage from '../images/testimonials/Jean-ClaudeMars.jpg';

const testimonials = [
  {
    id: 1,
    name: 'Willie Le Roux',
    text: 'IntouchSports has been a cornerstone in my career, offering unparalleled support and guidance at every step. Their dedication to my success on and off the field has been remarkable.',
    role: 'Fullback',
    image: WillieLeRouxImage
  },
  {
    id: 2,
    name: 'Canan Moodie',
    text: "IntouchSports excels both commercially and with my playing contract. Their expertise in securing top sponsorships and managing contract negotiations has been invaluable to my career",
    role: 'Wing',
    image: CananMoodieImage
  },
  {
    id: 3,
    name: 'Kurt-Lee Arendse',
    text: "IntouchSports has been amazing for my career. Their support and professionalism allow me to stay focused on my game, knowing they have everything else covered.",
    role: 'Wing',
    image: KurtLeeArendseImage
  },
  {
    id: 4,
    name: 'Ruan Nortje',
    text: "IntouchSports has been a game-changer for my career. Their support and dedication allow me to focus on my performance while they handle everything else with great professionalism. I'm grateful to have them in my corner.",
    role: 'Lock',
    image: RuanNortjeImage
  },
  {
    id: 5,
    name: 'Marcell Coetzee',
    text: "IntouchSports has opened doors for me to play with top teams like Kobe, Ulster, the Sharks, The Springboks, and The Bulls. Their support and connections have been vital in my career.",
    role: 'Flank',
    image: MarcellCoetzeeImgage
  },
  {
    id: 6,
    name: 'JC Mars',
    text: "IntouchSports truly cares about junior players like me. Their support and guidance have been amazing, helping me grow both on and off the field.",
    role: 'Fullback',
    image: JeanClaudeMarsImage
  }
];

const Testimonials = () => {
  const [current, setCurrent] = useState(0);
  
  // Using the theme to determine screen size
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handlePrev = () => {
    setCurrent(current === 0 ? testimonials.length - 1 : current - 1);
  };

  const handleNext = () => {
    setCurrent(current === testimonials.length - 1 ? 0 : current + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 15000);
    return () => clearInterval(interval);
  }, [current]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 2 }}>
      <Card sx={{ bgcolor: 'white', py: 4, borderRadius: 5, boxShadow: 3, width: '85%', position: 'relative' }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            <Typography 
              variant="h5" 
              component="div" 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                background: 'linear-gradient(45deg, #134083 30%, #5A9BD3 90%)', 
                borderRadius: 10, 
                p: 2, 
                color: 'white', 
                width: isSmallScreen ? '100%' : '50%', // Adjust width for small screens
                justifyContent: 'center', 
                fontWeight: 'bold', 
                boxShadow: 3 
              }}
            >
              <PeopleAltIcon sx={{ mr: 2 }} fontSize="large" /> Testimonials
            </Typography>
          </Box>
          <Box className="testimonials-container" sx={{ position: 'relative' }}>
            <IconButton onClick={handlePrev} aria-label="previous testimonial" sx={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', color: '#000' }}>
              <ArrowBackIosIcon />
            </IconButton>
            <Box className="testimonial-box" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box sx={{ flex: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 2 }}>
                <Typography 
                  variant="h6" 
                  className="testimonial-text" 
                  sx={{ 
                    height: isSmallScreen ? '300px' : '170px', // Increase height for small screens
                    overflow: 'hidden', 
                    textAlign: 'center' 
                  }}
                >
                  <blockquote style={{ fontStyle: 'italic', marginBottom: '15px' }}>{testimonials[current].text}</blockquote>
                </Typography>
              </Box>
              <Box 
                sx={{ 
                  flex: '40%', 
                  display: 'flex', 
                  flexDirection: 'column', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  minWidth: '150px',
                  mb: 1,
                }}
              >
                <img src={testimonials[current].image} alt={testimonials[current].name} className="testimonial-image" style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain' }} />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{testimonials[current].name}</Typography>
                <Typography variant="body2" sx={{ paddingBottom: '0px', fontWeight: 'bold' }}>{testimonials[current].role}</Typography>
              </Box>
            </Box>
            <IconButton onClick={handleNext} aria-label="next testimonial" sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', color: '#000' }}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Testimonials;
