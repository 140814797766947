import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Box, Typography, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import xLogo from '../images/xlogo.svg';
import '../pages/HomePage.css';

const YouTubeVideo = () => {
  const videos = [
    'https://www.youtube.com/embed/vK5GXXivXlI',
    'https://www.youtube.com/embed/6Iq_KR3AgI4',
    'https://www.youtube.com/embed/Pb6ASNUn9v4',
    'https://www.youtube.com/embed/Ekji_afoods',
    'https://www.youtube.com/embed/V9h6-kHs0QE',
  ];

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const nextVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Box
            sx={{
              position: 'relative',
              borderRadius: '10px',
              overflow: 'hidden',
              width: '85%',
              boxShadow: 3,
            }}
          >
        <Box
          sx={{
            width: '100%',
            background: 'linear-gradient(45deg, #134083 30%, #5A9BD3 90%)',
            color: 'white',
            padding: '10px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '15px px 0 0',
            zIndex: 1,
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            Experience the Action
          </Typography>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <IconButton href="https://x.com/InTouchSports" target="_blank" color="inherit">
              <img src={xLogo} alt="X Logo" style={{ width: 50, height: 50 }} /> {/* Slightly reduced the size */}
            </IconButton>
            <IconButton href="https://www.facebook.com/InTouchSports/" target="_blank" color="inherit">
              <FacebookIcon sx={{ color: 'white', fontSize: '2.8rem', width: '2.8rem', height: '2.8rem' }} /> {/* Slightly reduced the size */}
            </IconButton>
            <IconButton href="https://www.instagram.com/intouchsports/?hl=en" target="_blank" color="inherit">
              <InstagramIcon sx={{ color: 'white', fontSize: '2.8rem', width: '2.8rem', height: '2.8rem' }} /> {/* Slightly reduced the size */}
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ width: '100%', paddingBottom: '56.25%', position: 'relative' }}>
          <iframe
            src={videos[currentVideoIndex]}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: '0 0 0px 0px',
            }}
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            background: 'linear-gradient(45deg, #134083 30%, #5A9BD3 90%)',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0 0 8px 8px',
          }}
        >
          <Button
            variant="contained"
            onClick={() => setCurrentVideoIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length)}
            sx={{ margin: '0 10px', color: '#134083', backgroundColor: '#F5F5F5', '&:hover': { backgroundColor: '#5A9BD3' } }}
          >
            Previous Video
          </Button>
          <Button
            variant="contained"
            onClick={nextVideo}
            sx={{ margin: '0 10px', color: '#134083', backgroundColor: '#F5F5F5', '&:hover': { backgroundColor: '#5A9BD3' } }}
          >
            Next Video
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default YouTubeVideo;