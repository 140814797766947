import React from 'react';
import { Typography, Box, Button, Grid, Paper, Link } from '@mui/material';
import { styled } from '@mui/system';

const ContactPaper = styled(Paper)({
  padding: 20,
  maxWidth: 500,
  width: '100%',
  margin: 'auto',
});

const ContactUs = () => {
  const handleButtonClick = () => {
    //Todo: Update the URL to the correct path
    window.location.href = 'https://www.intouchsports.com/contact';
  };

  return (
    <Box textAlign="center" mt={0}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
          mb: 4,
          color: 'white',
          width: 'fit-content',
          mx: 'auto',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          Contact Us
        </Typography>
      </Box>
      <Box mt={3}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%" textAlign="left">
              <Typography variant="body1" mb={2} sx={{ fontSize: '1.25rem', lineHeight: 1.75 }}>
                Interested in learning more about how we can help you? Contact us at <Link href="mailto:office@intouchsports.com">office@intouchsports.com</Link> or visit our offices in France, Hong Kong, Japan, South Africa, and the United Kingdom.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactPaper>
              <Typography variant="h5" gutterBottom>
                Get in Touch
              </Typography>
              <Button 
                variant="contained" 
                color="primary" 
                fullWidth 
                onClick={handleButtonClick}
                sx={{ fontSize: '20px' }}  // Increase font size to 20px
              >
                Click Here to Contact Us
              </Button>
            </ContactPaper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ContactUs;
