import React, { useRef } from 'react';
import Slider from 'react-slick';
import TeamCard from '../TeamCard'; // Ensure the correct import path
import { useMediaQuery, useTheme, Fab } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../Carousel.css'; // Ensure the correct import path
import teamMembers from '../../data/team-members.json'; // Ensure the correct import path

const TeamCarousel = () => {
  const sliderRef = useRef();
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: isXSmall ? 1 : isSmall ? 2 : 4,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: false,
    variableWidth: false,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }
    ]
  };

  const members = teamMembers.team.flatMap(region => region.members);

  return (
    <div className="team_carousel-container">
      <Slider ref={sliderRef} {...settings}>
        {members.map((member, index) => (
          <div key={index} className="team-slick-slide-item">
            <div className="team_card-wrapper">
              <TeamCard key={index} member={member} className="team_card-carousel" />
            </div>
          </div>
        ))}
      </Slider>
      <Fab
        color="primary"
        aria-label="prev"
        className="team_fab-hidden"
        sx={{ position: 'absolute', top: '50%', left: 16, transform: 'translateY(-50%)', zIndex: 1000 }}
        onClick={() => sliderRef.current.slickPrev()}
      >
        <ArrowBackIcon />
      </Fab>
      <Fab
        color="primary"
        aria-label="next"
        className="team_fab-hidden"
        sx={{ position: 'absolute', top: '50%', right: 16, transform: 'translateY(-50%)', zIndex: 1000 }}
        onClick={() => sliderRef.current.slickNext()}
      >
        <ArrowForwardIcon />
      </Fab>
    </div>
  );
};

export default TeamCarousel;