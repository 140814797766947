import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemIcon, ListItemText, Grid, Card, CardContent, useMediaQuery, useTheme } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PeopleIcon from '@mui/icons-material/People';

// Use the S3 WebP version
const getty1 = 'https://webpfilesits.s3.eu-west-2.amazonaws.com/services1.webp';

const services = [
  { icon: <AccountBalanceIcon />, text: 'Financial Guidance', description: 'We offer sound financial guidance to help players manage their earnings, invest wisely, and plan for long-term financial security.' },
  { icon: <AssignmentIcon />, text: 'Mentoring and Guidance', description: 'Our team provides strategic advice and planning to help athletes navigate their careers, from early development to peak performance and beyond.' },
  { icon: <PeopleIcon />, text: 'Post-Career Opportunities', description: 'We assist athletes in preparing for life after rugby by exploring new career paths, educational opportunities, and business ventures.' }
];

const OffFieldServices = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen size is less than 600px

  return (
    <Container sx={{ bgcolor: 'white', color: 'black', py: 4, borderTopLeftRadius: 8, borderTopRightRadius: 8, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, boxShadow: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
        <Typography 
          variant="h4" 
          component="div" 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            background: 'linear-gradient(45deg, #134083 30%, #5A9BD3 90%)', 
            borderRadius: 8, 
            p: 2, 
            color: 'white', 
            width: isSmallScreen ? '100%' : '50%', // Adjust the width based on screen size
            justifyContent: 'center', 
            fontWeight: 'bold', 
            boxShadow: 3 
          }}
        >
          <AssignmentIcon sx={{ mr: 2 }} fontSize="large" /> Off Field Services
        </Typography>
      </Box>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box component="img" src={getty1} alt="Off Field Services" sx={{ width: '100%', borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <List>
            {services.map((service, index) => (
              <ListItem key={index} sx={{ display: 'block', mb: 5 }}>
                <Card sx={{ display: 'flex', alignItems: 'center', p: 2, boxShadow: 3, '&:hover': { boxShadow: 6 } }}>
                  <ListItemIcon sx={{ minWidth: '48px' }}>
                    {service.icon}
                  </ListItemIcon>
                  <CardContent>
                    <ListItemText
                      primary={<Typography variant="h6" sx={{ fontWeight: 'bold' }}>{service.text}</Typography>}
                      secondary={<Typography variant="body1">{service.description}</Typography>}
                    />
                  </CardContent>
                </Card>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OffFieldServices;