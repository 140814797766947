import React from 'react';
import { Typography, Grid, Box, Card, CardContent, Divider } from '@mui/material';
import { styled } from '@mui/system';
import SportsRugbyIcon from '@mui/icons-material/SportsRugby';
import StarIcon from '@mui/icons-material/Star';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PublicIcon from '@mui/icons-material/Public';

const achievements = [
  {
    icon: <SportsRugbyIcon fontSize="large" />,
    title: 'SPRINGBOKS',
    description: '10 of our clients brought the Webb Ellis Trophy back to South Africa!',
    image: 'https://webpfilesits.s3.eu-west-2.amazonaws.com/RWC.webp',
  },
  {
    icon: <StarIcon fontSize="large" />,
    title: 'ITS JUNIORS',
    description: '13 of our clients will represent the Junior Springboks in Australia for the inaugural Junior Rugby Championship.',
    image: 'https://webpfilesits.s3.eu-west-2.amazonaws.com/junior_boks.webp',
  },
  {
    icon: <AttachMoneyIcon fontSize="large" />,
    title: 'COMMERCIAL',
    description: 'Canan Moodie signed with Breitling, Kurt-Lee Arendse with Mercedes Benz, Sanele Nohamba and Cam Hanekom with Adidas, and 7 clients with Nike.',
    image: 'https://webpfilesits.s3.eu-west-2.amazonaws.com/commercial.webp',
  },
  {
    icon: <PublicIcon fontSize="large" />,
    title: 'OVERSEAS',
    description: '8 of our clients are playing in the UK Premiership, 12 in France\'s Top 14, and over 70 in Japan.',
    image: 'https://webpfilesits.s3.eu-west-2.amazonaws.com/overseas.webp',
  }
];

const SectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: 'linear-gradient(45deg, #134083 30%, #5A9BD3 90%)',
  borderRadius: 8,
  padding: theme.spacing(1),
  margin: theme.spacing(2, 0),
  color: 'white',
  justifyContent: 'center',
  fontWeight: 'bold',
  boxShadow: theme.shadows[3],
}));

const CardContainer = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  boxShadow: theme.shadows[3],
  backgroundColor: '#e0e0e0',
  '&:hover': { boxShadow: theme.shadows[6] },
}));

const KeyAchievements = () => {
  return (
    <Box backgroundColor='#0A2B5B' borderRadius='10px' color="#fff" px={2} textAlign="center" my={5}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'white',
          borderRadius: 2,
          padding: 2,
          boxShadow: 3,
          mb: 4,
          color: '#0A2B5B',
          width: 'fit-content',
          mx: 'auto',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          LATEST NEWS & ACHIEVEMENTS
        </Typography>
      </Box>
      <Grid container spacing={4} justifyContent="center">
        {achievements.map((achievement, index) => (
          <Grid item xs={12} md={6} key={index} sx={{ display: 'flex' }}>
            <CardContainer>
              <CardContent>
                <SectionHeader>
                  {achievement.icon}
                  <Typography variant="h6" sx={{ fontWeight: 'bold', ml: 1 }}>
                    {achievement.title}
                  </Typography>
                </SectionHeader>
                <Box component="img" src={achievement.image} alt={achievement.title} sx={{ width: '100%', borderRadius: '8px' }} />
                <Divider variant="middle" sx={{ bgcolor: '#00BFFF', width: '60px', margin: '1rem auto' }} />
                <Typography variant="body1" sx={{ fontSize: '1.25rem', lineHeight: 1.75 }}>
                  {achievement.description}
                </Typography>
              </CardContent>
            </CardContainer>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default KeyAchievements;