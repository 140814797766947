import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Typography, Grid, Container, useMediaQuery } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import TeamCard from '../components/TeamCard';
import TeamModal from '../components/TeamModal';
import Footer from '../components/Footer';

const teamBackgroundImages = {
  0: 'https://webpfilesits.s3.eu-west-2.amazonaws.com/france_back.webp',
  1: 'https://webpfilesits.s3.eu-west-2.amazonaws.com/japan_back.webp',
  2: 'https://webpfilesits.s3.eu-west-2.amazonaws.com/southafrica_back.webp',
  3: 'https://webpfilesits.s3.eu-west-2.amazonaws.com/uk_back.webp',
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`fullWidth-tabpanel-${index}`}
      aria-labelledby={`fullWidth-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default function TeamPage() {
  const theme = useTheme() || createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = useState(0);
  const [teams, setTeams] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    async function fetchMembers() {
      try {
        const response = await import('../data/team-members.json');
        if (response.default && Array.isArray(response.default.team)) {
          setTeams(response.default.team);
        } else {
          console.error("Data loaded is not as expected:", response);
        }
      } catch (error) {
        console.error("Failed to load team members:", error);
      }
    }
    fetchMembers();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleOpenModal = (member) => {
    setSelectedMember(member);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const getBackgroundImage = () => {
    return teamBackgroundImages[value] || '/images/france_back.jpg';
  };

  return (
    <ThemeProvider theme={theme}>
    <Box
      sx={{
        backgroundImage: `url(${getBackgroundImage()})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        position: 'relative',
        pt: 4,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.85)',
          width: '100%',
          maxWidth: '1200px',
          borderRadius: 2,
          boxShadow: 3,
          p: isMobile ? 2 : 3,
          mt: 0,
          mb: 4,
        }}
      >
        <Container>
          <Typography variant="h2" gutterBottom style={{ fontWeight: 'bold', textAlign: 'center' }}>
            OUR TEAM
          </Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant={isMobile ? "scrollable" : "fullWidth"}
            scrollButtons={isMobile ? "auto" : "off"}
            aria-label="team member regions"
            sx={{ mb: 2 }}
          >
            {teams.map((team, index) => (
              <Tab label={team.region} key={index} />
            ))}
          </Tabs>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            {teams.map((team, index) => (
              <TabPanel value={value} index={index} key={index}>
                <Grid container spacing={4} justifyContent="center">
                  {team.members.map((member, idx) => (
                    <Grid item key={idx}>
                      <TeamCard member={member} onClick={() => handleOpenModal(member)} />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            ))}
          </SwipeableViews>
        </Container>
      </Box>
      {selectedMember && (
        <TeamModal open={modalOpen} onClose={handleCloseModal} member={selectedMember} />
      )}
    </Box>
      <Footer />
    </ThemeProvider>
  );
}