import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const NavBar = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery('(min-width:595px) and (max-width:1000px)');

    const pages = [
        { label: 'Home', path: '/' },
        { label: 'About', path: '/about' },
        { label: 'Services', path: '/services' },
        { label: 'Team', path: '/team' },
        { label: 'Clients', path: '/clients' },
        { label: 'Contact', path: '/contact' }
    ];

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigateTo = (path) => {
        navigate(path);
        handleClose();
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: '#134083', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar sx={{ justifyContent: 'center', minHeight: '64px', paddingY: '10px' }}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2, display: { sm: 'none' } }}
                    onClick={handleMenu}
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    {pages.map((page) => (
                        <MenuItem key={page.label} onClick={() => navigateTo(page.path)}>{page.label}</MenuItem>
                    ))}
                </Menu>
                <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Logo" style={{ cursor: 'pointer', height: '70px', marginRight: '20px' }} onClick={() => navigate('/')}/>
                {!isMobile && pages.map((page) => (
                    <Button
                        key={page.label}
                        onClick={() => navigateTo(page.path)}
                        sx={{ 
                            my: 2, 
                            color: 'white', 
                            display: 'block', 
                            fontSize: '0.9rem', 
                            mx: isMediumScreen ? 1 : 5 
                        }}
                    >
                        {page.label}
                    </Button>
                ))}
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
