import React, { useState, useEffect } from 'react';
import ClientCarousel from '../components/ClientCarousel'; 
import BrandSlider from '../components/BrandSlider'; 
import YouTubeVideo from '../components/youtubevideo'; 
import Testimonials from '../components/Testimonials'; 
import Footer from '../components/Footer'; 
import { Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import './HomePage.css';

function HomePage() {
  const images = ['/images/home.jpg', '/images/home3.jpg'];
  const [selectedImage, setSelectedImage] = useState('');
  const [typedText, setTypedText] = useState('');
  const slogan = "Excellence in Sports Management";

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const randomImage = images[Math.floor(Math.random() * images.length)];
    setSelectedImage(randomImage);
  }, []);

  useEffect(() => {
    let index = 0;
    const typingInterval = setInterval(() => {
      if (index < slogan.length) {
        setTypedText(slogan.slice(0, index + 1));
        index += 1;
      } else {
        clearInterval(typingInterval);
      }
    }, 55);

    return () => clearInterval(typingInterval);
  }, [slogan]);

  return (
    <div className="gradient-background">
      <div
        style={{
          backgroundImage: `url(${selectedImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '80vh',
          display: 'flex',
          alignItems: 'center', // Center the content vertically
          justifyContent: 'center', // Center the content horizontally
          position: 'relative',
          color: 'white',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        }}
      >
        <h1 style={{ fontSize: '3.5rem', fontWeight: 'bold' }}>{typedText}</h1>
      </div>
      <div 
        className="content-wrapper" 
        style={{ padding: isSmallScreen ? '0' : 'initial' }}
      >
        <div style={{ margin: '25px' }}></div>
        <YouTubeVideo />
        <div style={{ margin: '80px' }}></div>

        <ClientCarousel />
        <div style={{ margin: '25px' }}></div>
        <BrandSlider />
        <div style={{ margin: '25px' }}></div>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
        </Box>
        <Testimonials />
        <div style={{ margin: '25px' }}></div>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
