import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import ClientCard from '../components/ClientCard';
import ClientModal from '../components/ClientModal';
import Footer from '../components/Footer';

export default function ClientsPage() {
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isExtraSmallScreen = useMediaQuery('(max-width:450px)');

    useEffect(() => {
        async function fetchClients() {
            try {
                const response = await import('../data/clients.json');
                if (response.default && Array.isArray(response.default.clients)) {
                    setClients(response.default.clients);
                } else {
                    console.error("Data loaded is not as expected:", response);
                }
            } catch (error) {
                console.error("Failed to load client data:", error);
            }
        }
        fetchClients();
    }, []);

    const handleOpenModal = (client) => {
        setSelectedClient(client);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            <Box
                sx={{
                    backgroundImage: `url('https://webpfilesits.s3.eu-west-2.amazonaws.com/OurClient.webp')`,  // Updated path
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: '100vh',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.85)', 
                        width: '100%',
                        maxWidth: '1200px',
                        borderRadius: 2,
                        boxShadow: 3,
                        p: isSmallScreen ? 1 : 3, // Reduced padding on small screens
                        mt: 4,
                        mb: 4,
                    }}
                >
                    <Container>
                        <Typography variant="h2" gutterBottom style={{ fontWeight: 'bold', textAlign: 'center' }}>
                            OUR CLIENTS
                        </Typography>
                        <Grid 
                            container 
                            spacing={isSmallScreen ? 0 : 2} // Set spacing to 0 on small screens
                            justifyContent="center" 
                            alignItems="center"
                        >
                            {clients.map((client, index) => (
                                <Grid 
                                    item 
                                    key={index} 
                                    xs={12} 
                                    sm={12} 
                                    md={6} 
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: isSmallScreen ? 0 : 2, // Remove padding on small screens
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            maxWidth: isExtraSmallScreen ? 'calc(100% - 30px)' : isSmallScreen ? 'calc(100% - 30px)' : '100%', 
                                            margin: isSmallScreen ? '0 auto' : '0 auto',  // Ensure card is centered on small screens
                                            padding: isSmallScreen ? 0 : 'initial',
                                        }}
                                    >
                                        <ClientCard client={client} onClick={() => handleOpenModal(client)} />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                        {selectedClient && (
                            <ClientModal open={modalOpen} onClose={handleCloseModal} client={selectedClient} />
                        )}
                    </Container>
                </Box>
            </Box>
            <Footer/>
        </>
    );
}
