import React, { useState } from 'react';
import { Card, CardActionArea, CardContent, Typography, Avatar } from '@mui/material';
import TeamModal from './TeamModal';

function TeamCard({ member }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Card sx={{ maxWidth: 345, minWidth: 250, margin: '20px', boxShadow: 3, textAlign: 'center', backgroundColor: '#e0e0e0' }}>
        <CardActionArea onClick={handleOpen}>
          <Avatar 
            src={process.env.PUBLIC_URL + '/' + (member.image || 'images/default.png')}
            alt={member.name} 
            sx={{ width: 140, height: 140, margin: '20px auto' }}
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {member.name.toUpperCase()}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ minHeight: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {member.title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <TeamModal open={open} onClose={handleClose} member={member} />
    </>
  );
}

export default TeamCard;