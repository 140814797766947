import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import BusinessIcon from '@mui/icons-material/Business';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import StarIcon from '@mui/icons-material/Star';
import LanguageIcon from '@mui/icons-material/Language';

const sections = [
  {
    title: 'Who We Are',
    description: 'In Touch Sports is committed to empowering athletes to achieve sustainable success through service excellence. Our experienced team provides personalized support to players at all levels, offering expert guidance in contract negotiations, career development, financial planning, commercial endorsements, post-career opportunities, and brand management. Passionate about rugby, we are dedicated to helping our clients thrive both on and off the field.',
  },
  {
    title: 'What We Do',
    description: 'At In Touch Sports, we provide comprehensive services tailored to the unique needs of our clients. Our dedicated team works closely with players to support their career growth and maximize their earning potential both on and off the field. At In Touch Sports, our goal is to ensure that our clients succeed in every aspect of their careers, achieving lasting impact and fulfilment.',
  },
  {
    title: 'Our Mission',
    description: 'Our mission is to empower athletes to achieve their full potential both on and off the field. We believe in fostering strong, long-term relationships with our athletes, based on trust, transparency, and mutual respect. Whether you are an emerging talent or an established player, In Touch Sports is here to support your journey every step of the way.',
  },
  {
    title: 'Global Footprint',
    description: '', // Detailed description handled separately
  }
];

const SectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'linear-gradient(45deg, #134083 30%, #5A9BD3 90%)',
  borderRadius: 8,
  padding: theme.spacing(1),
  margin: theme.spacing(2, 'auto'),
  color: 'white',
  fontWeight: 'bold',
  boxShadow: theme.shadows[3],
  width: '55%', // Default width for larger screens
  [theme.breakpoints.down('sm')]: {
    width: '100%', // Set width to 100% for small screens
  },
}));

const WhoWeAre = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box component="img" src="https://webpfilesits.s3.eu-west-2.amazonaws.com/whoweare.webp" alt="Who We Are" sx={{ width: '100%', borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3, backgroundColor: '#e0e0e0', '&:hover': { boxShadow: 6 }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CardContent sx={{ px: { xs: 2, sm: 6 } }}> {/* Reduced padding for small screens */}
              <SectionHeader>
                <BusinessIcon sx={{ mr: 2 }} fontSize="large" />
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                  {sections[0].title}
                </Typography>
              </SectionHeader>
              <Typography variant="body1" sx={{ fontSize: '1rem', lineHeight: 1.75 }}>{sections[0].description}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3, backgroundColor: '#e0e0e0', '&:hover': { boxShadow: 6 }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CardContent sx={{ px: { xs: 2, sm: 6 } }}> {/* Reduced padding for small screens */}
              <SectionHeader>
                <WhatshotIcon sx={{ mr: 2 }} fontSize="large" />
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                  {sections[1].title}
                </Typography>
              </SectionHeader>
              <Typography variant="body1" sx={{ fontSize: '1rem', lineHeight: 1.75 }}>{sections[1].description}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box component="img" src="https://webpfilesits.s3.eu-west-2.amazonaws.com/whatwedo.webp" alt="What We Do" sx={{ width: '100%', borderRadius: '8px' }} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box component="img" src="https://webpfilesits.s3.eu-west-2.amazonaws.com/ourmission.webp" alt="Our Mission" sx={{ width: '100%', borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3, backgroundColor: '#e0e0e0', '&:hover': { boxShadow: 6 }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CardContent sx={{ px: { xs: 2, sm: 6 } }}> {/* Reduced padding for small screens */}
              <SectionHeader>
                <StarIcon sx={{ mr: 2 }} fontSize="large" />
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                  {sections[2].title}
                </Typography>
              </SectionHeader>
              <Typography variant="body1" sx={{ fontSize: '1rem', lineHeight: 1.75 }}>{sections[2].description}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3, backgroundColor: '#e0e0e0', '&:hover': { boxShadow: 6 }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CardContent sx={{ px: { xs: 2, sm: 6 } }}> {/* Reduced padding for small screens */}
              <SectionHeader>
                <LanguageIcon sx={{ mr: 2 }} fontSize="large" />
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {sections[3].title}
                </Typography>
              </SectionHeader>
              <Typography variant="body1" paragraph align="center" sx={{ fontSize: '1.25rem' }}>
                “A SOUTH AFRICAN AGENCY FOR SOUTH AFRICAN PLAYERS”
              </Typography>
              <Box display="flex" justifyContent="space-around" >
                <Box textAlign="center">
                  <Typography variant="h2">13</Typography>
                  <Typography variant="body1">AGENTS</Typography>
                </Box>
                <Box textAlign="center">
                  <Typography variant="h2">90</Typography>
                  <Typography variant="body1">PLAYERS ABROAD</Typography>
                </Box>
                <Box textAlign="center">
                  <Typography variant="h2">6</Typography>
                  <Typography variant="body1">Countries</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box component="img" src="https://webpfilesits.s3.eu-west-2.amazonaws.com/global-footprint.webp" alt="Global Footprint Image" sx={{ width: '100%', borderRadius: '8px' }} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default WhoWeAre;