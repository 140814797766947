import React from 'react';
import { Modal, Box, Typography, Button, Avatar, useMediaQuery, useTheme } from '@mui/material';

function TeamModal({ open, onClose, member }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // Screen size check for 1000px and below

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="team-member-details"
      aria-describedby="team-member-more-info"
    >
      <Box sx={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        width: '80%', 
        maxWidth: 800, 
        bgcolor: 'background.paper', 
        boxShadow: 24, 
        p: 4, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        borderRadius: '10px'
      }}>
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: isSmallScreen ? 'column' : 'row', // Change direction based on screen size
            width: '100%', 
            mb: 2 
          }}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              width: isSmallScreen ? '100%' : '40%', 
              mr: isSmallScreen ? 0 : 2, 
              mb: isSmallScreen ? 2 : 0 // Add margin bottom for small screens
            }}
          >
            <Avatar 
              src={process.env.PUBLIC_URL + '/' + (member.image || 'images/default.png')} 
              alt={member.name} 
              sx={{ 
                width: '100%', 
                height: 'auto', 
                borderRadius: '10px',
                maxWidth: isSmallScreen ? 250 : '100%', // Set max width for small screens
                maxHeight: isSmallScreen ? 250 : 'auto' // Set max height for small screens
              }}
            />
          </Box>
          <Box sx={{ width: isSmallScreen ? '100%' : '60%' }}>
            <Typography id="team-member-name" variant="h6" component="h2" sx={{ mt: 2, fontWeight: 'bold' }}>
              {member.name}
            </Typography>
            <Typography id="team-member-text" sx={{ mt: 1, mb: 2 }}>
              {member.text}
            </Typography>
          </Box>
        </Box>
        <Button onClick={onClose} variant="contained" sx={{ mt: 2 }}>
          Close
        </Button>
      </Box>
    </Modal>
  );
}

export default TeamModal;
