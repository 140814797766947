import React from 'react';
import { Box, Typography, Container, useMediaQuery, useTheme } from '@mui/material';
import OffFieldServices from '../components/OffFieldServices';
import CommercialServices from '../components/CommercialServices';
import BrandSlider from '../components/BrandSlider';
import Footer from '../components/Footer';

const Services = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Hook to check if the screen is small

  return (
    <Box
      sx={{
        backgroundImage: `url(https://webpfilesits.s3.eu-west-2.amazonaws.com/getty2.webp)`,  // Updated path
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Container sx={{ textAlign: 'left', py: 4, color: 'white', flexGrow: 1 }}>
        <Typography variant="h2" component="h1" sx={{ fontWeight: 'bold' }}>
          Services
        </Typography>
        <Typography variant="h6" component="p" sx={{ mt: 2 }}>
          In Touch Sports offers a comprehensive suite of services designed to support our clients at every stage of their career. 
          Our personalized approach ensures that each player receives the expert guidance and resources they need to excel both 
          on and off the field.
        </Typography>
      </Container>
      <Box sx={{ pt: 2, pb: 4, flexGrow: 1 }}>
        <OffFieldServices />
        <CommercialServices />
        <Box sx={{ mt: isSmallScreen ? 4 : 0 }}> {/* Apply margin-top on small screens */}
          <BrandSlider />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Services;
